.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.logo {
    width: 200px;
    cursor: pointer;
    margin-top: -10px;
    @media (max-width: 500px) {
        width: 150px;
        margin-top: -5px;
    }
}
.left {
    display: flex;
    align-items: center;
}

.dropdown_menu {
    width: 100%;
    text-align: center;
}

.user_info {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    @media (max-width: 768px) {
        gap: 5px;
    }
}
.user_photo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    @media (max-width: 768px) {
        width: 20px;
        height: 20px;
    }
}

.logout {
    color: red;
    cursor: pointer;
}

.lines {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 7px;
    cursor: pointer;
    margin-right: 20px;
    width: 30px;
    @media (max-width: 768px) {
        margin-right: 3px;
    }
}

.line {
    height: 3px;
    background-color: blue;
    transition: all 0.5s;
    gap: 5px;
    border-radius: 2px;
    transition: .5s all;
}

.lines:hover .line {
    width: 100%;
}

.line1 {
    width: 50%;
}

.line2 {
    width: 100%;
}

.line3 {
    width: 70%;
}
.notifications {
    position: relative;
    cursor: pointer;
  
    .bell {
      font-size: 1.5rem;
      color: #333;
    }

    .badge {
      position: absolute;
      top: -5px;
      right: -5px;
      background-color: red;
      color: white;
      padding: 1.5px 6px;
      border-radius: 50%;
      font-size: 0.6rem;
    }

}

.read {
    opacity: .5;
    text-decoration: line-through;
}

.no_read {
    opacity: 1;
    font-weight: bold;
}

.actions {
    width: 32px;
    cursor: pointer;
    a {
        color: black;
    }
}

.message {
    margin: 5px 0;
}