.actions {
    width: 120px;
    & svg { 
        font-size: 20px;
        cursor: pointer;
        &:not(:first-child) { 
            margin-left: 20px;
        }
    }
}

.td_progressbar {
    width: 30%;

    @media(max-width: 1000px) {
        width: 40%;
    }
    @media (max-width: 765px) {
        width: 30%;
    }
}
.date {
    font-size: 12px;
    color: #999;
    font-style: italic;
}
.info {
    text-align: center;
    margin: 20px 0;
}
.titulo {
    font-family: cocogoose;
}

.checkbox {
    margin-left: 4px;
}