.cardLink{
    text-decoration: none;
    color: #000;
}

.cardEstoque{
    height: 320px;
    width: 300px;
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.24);
}

.cardTop{
    height: 275px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px 30px 0 0;
}

.cardColor{
    height: 45px;
    border-radius: 0 0 30px 30px;
    transition: 0.3s all;
    font-family: cocogoose;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EDEDED;
}

.estoque{
    height: auto;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: auto;

    &_cards {
        display: grid;
        width: 70%;
        grid-template-columns: repeat(3, 300px);
        column-gap: 60px;
        row-gap: 40px;
        text-align: center;
        place-content: center;
        padding-bottom: 50px;
        place-items: center;
    }
}