.body {
    width: 100%;
    height: 100vh;
    background: #2041ff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 170px;
}

.card {
    min-width: 200px;
    min-height: 200px;
    width: 400px;
    height: 450px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.37);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
    padding-top: 10px;
}

.form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.input_div {
    width: 100%;
}

#submit {
    margin-top: 20px;
    width: 100%;
    background-color: #2041ff;
}

.body_loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error { 
    color: red;
    font-size: 12px;
    margin-top: 5px;
}