.footer {
    width: 100%;
    height: 220px;
    background-color: #6b1d26;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: auto;
    padding: 20px;

}

.ul {
    list-style: none;
    padding: 0;
    text-align: center;
}

.logo {
    max-width: 200px;
}

.a {
    text-decoration: none;
    color: white;
    margin: 10px;
}