.message { 
    max-width: 200px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
}

.link {
    max-width: 100px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
}

// duplicate a link
.duplicate_btn {
    cursor: pointer;
    color: #007bff;
    text-decoration: underline;
}