
.filtro {
    margin: 3rem 0 !important;
    width: 100%;
}

.form {
    gap: 0.5rem !important;
}
.form_buttons {
    display: flex;
    float: right;
    margin-top: 10px;
    gap: .5rem;
}
.date {
    font-size: 12px;
    color: #999;
    font-style: italic;
    text-align: center;
}


.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.td_progressbar {
    width: 30%;

    @media(max-width: 1000px) {
        width: 40%;
    }
    @media (max-width: 765px) {
        width: 30%;
    }
}

.previsao {
    & h4 {
        font-family: cocogoose;
    }
    & .comissao {
        width: 100%;
        justify-content: space-between;
    }
    & div {
        display: flex;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}

.eye {
    cursor: pointer;
    font-size: 25px;
}