.perfil {
    width: 100%;
    margin-bottom: 5px;
}

.editPerfil{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 700px) {
        flex-direction: column;
    }
}

.imgPerfil{
    width: 200px;
    height: 200px;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    cursor: pointer;
}

.imgPerfil:hover{
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.textName{
    font-size: 30px;
    font-family: os-regular;
    font-weight: bold;
    margin-top: 12px;
    
    @media (max-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 415px) {
        font-size: 25px;
        font-weight: bold;
    }
    @media (max-width: 320px) {
        font-size: 18px;
    }
}

.textEmail{
    font-family: os-regular;
    font-size: 20px;
    margin-top: 5px;
    @media (max-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 415px) {
        font-size: 16px;
    }
}

.submit_novo{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.link_documentos {
    text-decoration: none;
    color: inherit;
}

.documento_cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.documento_card {
    width: 120px;
    height: 120px;
    border: 1px solid rgba(0, 0, 0, 0.185);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.documento_adicionar {
    font-size: 50px;
    border: none;
}
.icon_documento {
    font-size: 50px;
    color: #000;
}

.graficos_section {
    margin-top: 20px;
    width: 100%;
}

.graficos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
}

.grafico {
    padding: 40px;
    padding-bottom: 20px;
}

.grafico_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.grafico_titulo {
    font-size: 20px;
    color: #000000c7;

    & span {
        font-size: 12px;
        color: #999;
        margin-bottom: 10px;
        font-style: italic;
        margin-left: 15px;
    }
}

.pie_chart {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
}

// Documents 
.img_doc {
    width: 100%;
    height: 100%;
    object-fit: cover;
}