.titulo {
    font-size: 1rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.534);
    margin: 2rem 0 1rem 2rem;
    text-transform: uppercase;
}

.itens {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
}

.item {
    padding: 0.5rem 0;
    margin: 0 4rem;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.534);
    cursor: pointer;
    transition: 0.3s;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    &:hover {
        color: rgba(0, 0, 0, 0.9);
    }
    font-family: os-regular;
}

.item p {
    margin-bottom: 0;
    margin-top: 1px;
    margin-left: 1rem;
}
.arrow {
    transition: all 0.2s ease;
    transform: rotate(0deg);
}
.arrow.open {
    transform: rotate(90deg);
}
.dropdown {
    max-height: 0;
    overflow: hidden;
    transition: all .5s ease;
}

.dropdown.open {
    max-height: 200px; 
}

.dropdown_item {
    margin-left: 40px !important;
}