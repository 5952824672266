.cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 20px; 
    width: 100%;
    @media (max-width: 1000px) {
        grid-template-columns: repeat(auto-fill, minmax(47%, 1fr));
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
}

.scroll_y {
    overflow-y: auto;
    max-height: 70vh;
}
