.showPass {
    cursor: pointer;
    margin-left: 5px;
}

.checkbox {
    margin-left: 4px;
}

.editar_pass_div {
    position: absolute;
    right: 10px;
    top: 53%
}


.ellipsedText {
    // display: inline-block;
    max-width: 200px; /* ajuste o valor conforme necessário */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
}

.inactive_access {
    td {
        background-color: rgba(255, 0, 0, 0.411) !important;
    }
}