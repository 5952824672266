.graphs {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
    width: 100%;
}

.graph {
    display: flex;
    padding: 20px;
}

.filtro {
    margin: 1rem 0 3rem 0 !important;
    width: 100%;
}

.date {
    font-size: 12px;
    color: #999;
    font-style: italic;
    text-align: center;
}