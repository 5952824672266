.btnEdit{
    width: 2%;
    text-align: center;
}

.valorCampo{
    display: flex;
    align-items: center;
    gap: 10px;
}
