.bancos{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    text-align: center;
    gap: 30px;
    
    @media (max-width: 1000px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 760px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 450px) {
        grid-template-columns: repeat(1, 1fr);
    }
}


.banco {
    position: relative;

    & img {
        
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        transition: all 0.3s ease-in-out;
        cursor: pointer;    
    }
    & img:hover {
        transform: scale(1.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);    
    }

    & .btn_editDelete{
        margin-top: 10px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }

}

.checkbox {
    margin-left: 4px;
}

.div_bank {
    position: relative;
}

.hover_bank::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e5e5e5;
    top: -10px;
    right: 50%;
    transform: translateX(50%);
}

.hover_bank {
    z-index: 2000;
    position: absolute;
    display: none;
    background-color: #e5e5e5;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    bottom: -20%;
    left: 50%;
    transform: translateX(-50%);
    transition: all .3s;
}

.hover_bank.show {
    display: block;
}