.skeleton {
    width: 700px;
    height: 20px;
}

.evidence {
    margin-bottom: 10px;

    &_text {
        word-wrap: break-word;
        white-space: normal;
    }

    &_actions {
        display: flex;
        justify-content: center;
        gap: 10px;
        width: 100%;
    }
}
