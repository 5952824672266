.logo {
    width: 200px;
}

.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.5s all;
    pointer-events: none; // para evitar interações quando a opacidade é 0
}

.backdrop_active {
    left: 400px;
    opacity: 1;
    pointer-events: auto; // para permitir interações quando a opacidade é 1
}

.sideBar {
    z-index: 9999;
    width: 400px;
    height: 100%;
    position: fixed;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);    background-color: white;
    left: -400px;
    transition: all 0.5s;
    z-index: 9999;
    overflow-y: auto;
}

.expanded {
    left: 0;
    transition: all 0.5s;
}

.nav_btn {
    font-size: 40px;
    cursor: pointer;
    color: blue;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);    background-color: white;
    padding: 5px;
}

.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.icon {
    font-size: 23px;
    opacity: 0.7;
}