.password_div {
    display: flex;
    gap: 10px;
    align-items: center;
}
.showPass {
    cursor: pointer;
}

.checkbox {
    margin-left: 4px;
}

.editar_pass_div {
    position: absolute;
    right: 10px;
    top: 53%
}

.botoes {
    width: 50px;
}

.inativo {
    opacity: .4;
}