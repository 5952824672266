.automacoes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 20px; 
    width: 100%;
    @media (max-width: 1000px) {
        grid-template-columns: repeat(auto-fill, minmax(47%, 1fr));
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
}

.link {
    color: inherit;
    text-decoration: none;
}

.card {
    position: relative;
    min-height: 450px;
    cursor: pointer;
    border-radius: 0.375rem;
    border: 1px solid #dee2e6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    overflow-wrap: break-word;
}

.card_img {
    max-width: 100px;
    max-height: 100px;
    width: 100px;
    height: 100px;
    object-fit: contain;
    object-position: center;
}

.card_content {
    text-align: center;
}

.card_content h3 {
    margin-top: 1rem;
    font-size: 1.4rem;
    font-family: inter-bold;
}
.card_content p {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: os-regular;
}

.card_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
}

.card_body p {
    margin: 0;
}

.date {
    font-size: 12px;
    color: #999;
    margin-bottom: 10px;
    font-style: italic;
}

.card_footer {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 2rem;
}

.form {
    width: 100%;
}