.qtLigacoes{
    width: 20%;
}

.valorCampo{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.valorCampo > div {
    flex: 1 1 calc(50% - 10px); 
    box-sizing: border-box;
}

.iconRemove {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 10px; 
}

.iconAdd {
    margin: 0;
}