.notfound {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  &__left {
    text-align: center;
    width: 40%;
    &--top {
      h1 {
        font-family: heycomic;
        font-size: 140px;
        font-weight: 100;
        color: black;
      }
      h2 {
        font-family: montserrat;
        font-weight: 500;
        color: black;
      }
      button {
        text-transform: uppercase;
        outline: none;
        border: none;
        padding: 10px;
        border-radius: 10px;
        font-size: 20px;
        background-color: #FF0000;
        color: white;
        font-family: inter-bold;
        cursor: pointer;
        margin-top: 20px;
        transition: 0.5s all;
        &:hover {
          transform: scale(1.05);
          background-color: #eb4d4b;
        }
      }
    }
    &--bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 100px;
      font-family: montserrat;
      color: #eb4d4b;
      font-weight: bold;
      p {
        font-size: 20px;
        margin-left: 5px;
      }
    }
  }
  &__right {
    img {
      width: 700px;
    }
  }
}

@media (max-width: 1200px) {
  .notfound {
    flex-direction: column;
    padding-top: 50px;
    &__left {
      width: 100%;
      &--top {
        h1 {
          font-size: 100px;
        }
        h2 {
          font-size: 20px;
        }
        button {
          font-size: 18px;
        }
      }
      &--bottom {
        margin-top: 20px;
      }
    }
    &__right {
      img {
        width: 100%;
      }
    }
  }
}
