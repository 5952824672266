.filter {

    width: 100%;
    margin-bottom: 3rem;
    display: flex;

    & input {
        width: 90%;
    }
    & button {
        width: 8%;
    }
}

.bases {
    width: 100%;
    margin-top: 2rem;
    & h4 {
        margin-bottom: 1rem;
        font-family: 'cocogoose';
        font-size: 20px;
    }
}

.proposals {
    width: 100%;
    margin-top: 2rem;
    & h4 {
        margin-bottom: 1rem;
        font-family: 'cocogoose';
        font-size: 20px;
    }
}

.details {
    width: 20px;
    text-align: center;
}