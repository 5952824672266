.comunicado {
    padding: 1.5rem 0;
    border-bottom: 1px solid #e5e5e5;
    width: 900px;
    @media (max-width: 1000px) {
        width: 100%;
    }
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.date {
    font-size: 12px;
    color: #999;
    margin-bottom: 10px;
    font-style: italic;
}
.autor_foto {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 2px;
}

.body {
    word-wrap: break-word;
}

.body img {
    width: 100%;
    margin-bottom: 1rem;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.visualizadores {
    display: flex;
    gap: 3px;
}

.visualizador {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.importancia_img {
    width: 20px;
    height: 20px;
    margin-bottom: .25rem;
}
.admin_buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.textarea {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.form {
    width: 80%;
}

.submit_novo {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.link_visitarperfil {
    margin-left: 10px;
    font-size: 14px;
}