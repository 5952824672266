.tutorial_info {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    gap: 2%;
    width: 100%;
    @media (max-width: 900px){
        flex-direction: column;
        gap: 0;
    }
}

.tutorial_descricao {
    font-family: montserrat;
    width: 49%;
    margin-top: 30px;
    @media (max-width: 900px){
        width: 100%;
    }
}

.aulas {
    width: 49%;
    & h4 {
        margin-top: 0 !important;
    }
    @media (max-width: 900px){
        width: 100%;
    }
}

.aulas_cards {
    width: 100%;
}

.aula {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    cursor: pointer;
    transition: .4s all;
    margin-top: 5px;
    border-radius: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.207);

    &:hover {
        box-shadow: 0 3px 10px rgba(0, 0, 0, .4);
    }
}
 
.link {
    text-decoration: none;
    color: inherit;
}

.checkbox {
    margin-left: 4px;
}

.aulas_opt {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 1rem;
}

.form {
    width: 100%;
}