.icons_columns {
    width: 2%;
}
.date {
    font-size: 12px;
    color: #999;
    font-style: italic;
    margin-top: 5px;
}
.disabled {
    color: #999;
}

.text_right {
    text-align: right;
}

.backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: -1;
    display: none;
}
.backdrop.visible {
    display: block;
}

.details_button {
    background-color: #f5f5f5;
    // border-radius: 5px;
    padding: 5px 10px;
    color: #333;
    position: relative;
    cursor: pointer;
    & .open_details {
        background-color: #e5e5e5;
        opacity: 0;
        border-radius: 5px;
        position: absolute;
        right: 50%;
        transform: translateX(50%) translateY(-10px);
        width: 400px;
        // max-height: 150px;
        z-index: 100;
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        transition: opacity 0.3s ease, transform 0.3s ease;
        visibility: hidden;

        &::before { 
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #e5e5e5;
            top: -10px;
            right: 50%;
            transform: translateX(50%);
        }

        &.visible {
            opacity: 0.9;
            transform: translateX(50%) translateY(0);
            visibility: visible;
        }

        & .actions {
            width: 70%;
            display: flex;
            justify-content: space-evenly;
            margin: 5px 0;
        }
    }
}

.evaluated {
    color: #333;
    padding: 5px 10px;
}

.btnBase{
    margin: 20px auto 0 20px;
}
.base_infos {
    width: 100%;
}

.base_infos_table {
    margin-top: 20px;
}

.view_base_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.proposals {
    width: 100%;
    margin-top: 2rem;
    & h4 {
        margin-bottom: 1rem;
        font-family: 'cocogoose';
        font-size: 20px;
    }
    .details {
        width: 50px;
        text-align: center;
    }
}
.graph_section {
    width: 100%;
    margin-top: 4rem;
    & h4 {
        margin-bottom: 1rem;
        font-family: 'cocogoose';
        font-size: 20px;
    }
}

.graphs_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;

    & .graph_div {
        background-color: #ececec;
        border-radius: 5px;
        padding: 20px;
    }
}

.checkbox {
    margin-left: 4px;
}


// PERMISSION MODAL

.sellers_container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
  
.sellers_container .form_check {
    margin-bottom: 5px;
}

.customer_checked td {
        background-color: rgba(15, 179, 0, 0.441) !important;
}